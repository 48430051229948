import { render, staticRenderFns } from "./GZHRecord.vue?vue&type=template&id=3d38d541&scoped=true&"
import script from "./GZHRecord.vue?vue&type=script&lang=js&"
export * from "./GZHRecord.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d38d541",
  null
  
)

export default component.exports
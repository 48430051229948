import Vue from "vue";
import VueI18n from "vue-i18n";
import lang_en from "element-ui/lib/locale/lang/en";
import lang_cn from "element-ui/lib/locale/lang/zh-CN";
import lang_hk from "element-ui/lib/locale/lang/zh-TW";
import locale from "element-ui/lib/locale";
// import zhCN from "vxe-table/lib/locale/lang/zh-CN.js";
// import enUS from "vxe-table/lib/locale/lang/en-US.js";
import VXETable from "vxe-table";

//esaytable
import easyTable_en from "vue-easytable/libs/locale/lang/en-US.js";
import easyTable_cn from "vue-easytable/libs/locale/lang/zh-CN.js";
import easyTable_hk from "vue-easytable/libs/locale/lang/zh-TW.js";
import { VeLocale } from "vue-easytable";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: window.localStorage.getItem("lang") ? window.localStorage.getItem("lang") : "CN",
  messages: {
    CN: require("../assets/common/lang/CN.js"),
    EN: require("../assets/common/lang/EN.js"),
    HK: require("../assets/common/lang/HK.js"),
  },
});

if (window.localStorage.getItem("lang") == "EN") {
  locale.use(lang_en);
  VeLocale.use(easyTable_en);
} else if (window.localStorage.getItem("lang") == "HK") {
  locale.use(lang_hk);
  VeLocale.use(easyTable_hk);
} else {
  locale.use(lang_cn);
  VeLocale.use(easyTable_cn);
}

VXETable.setup({
  // 对组件内置的提示语进行国际化翻译
  i18n: (key, args) => i18n.t(key, args),
});
export default i18n;

<template>
  <div>
    <vxe-form ref="form" :rules="formRules" :loading="loading" :data="formData" @submit="updateConfig" title-width="120">
      <vxe-form-item :title="$t('lang.背光时长') + '(' + $t('lang.分钟') + ')'" field="lightTime" span="22" title-align="right">
        <template #default="params">
          <vxe-input v-model="params.data.lightTime" type="integer" :placeholder="$t('lang.请输入背光时长')" clearable></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item :title="$t('lang.采样间隔') + '(' + $t('lang.分钟') + ')'" field="intervalTime" span="22" title-align="right">
        <template #default="params">
          <vxe-input v-model="params.data.intervalTime" type="integer" :placeholder="$t('lang.请输入采样间隔')" clearable></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item :title="$t('lang.对接公司名称')" span="22" title-align="right">
        <template #default="params">
          <vxe-select v-model="params.data.companyName" :placeholder="$t('lang.请选择')" clearable>
            <vxe-option v-for="item in optionsDataType" :key="item.dataValue" :value="item.dataValue" :label="item.dataText"></vxe-option>
          </vxe-select>
        </template>
      </vxe-form-item>
      <vxe-form-item :title="$t('lang.是否开机升级')" span="14" title-align="right">
        <template #default="params">
          <vxe-switch v-model="params.data.ifUpgrade" size="medium" :disabled="isSwitchDisabled" :open-label="$t('lang.是')" :close-label="$t('lang.否')"></vxe-switch>
        </template>
      </vxe-form-item>
      <vxe-form-item span="8" field="pwd">
        <template #default="params">
          <vxe-input v-model="params.data.pwd" :placeholder="$t('lang.请输入密码')" @input="checkPassword" clearable></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item align="right" span="24">
        <template #default>
          <div style="padding-top:120px">
            <vxe-button status="info" :content="$t('lang.关闭')" @click="close"></vxe-button>
            <vxe-button type="submit" status="primary" :content="$t('lang.保存')"></vxe-button>
          </div>
        </template>
      </vxe-form-item>
    </vxe-form>
  </div>
</template>

<script>
export default {
  props: ["serialNumber"],
  data() {
    let that = this;
    return {
      loading: false,
      isSwitchDisabled: true,
      optionsDataType: [],
      pageSize: 50,
      currentPage: 1,
      total: 0,
      formData: {
        id: 0,
        serialNumber: that.serialNumber,
        lightTime: 0,
        intervalTime: 0,
        ifUpgrade: false,
        companyName: "",
        pwd: "",
      },
      formRules: {
        lightTime: [
          { required: true, message: that.$t("lang.请输入背光时长") },
          { type: "number", message: that.$t("lang.必须为数字值") },
          { pattern: /^[0-9]*$/, message: that.$t("lang.必须为整数") },
        ],
        intervalTime: [
          { required: true, message: that.$t("lang.请输入采样间隔") },
          { type: "number", message: that.$t("lang.必须为数字值") },
          { pattern: /^[0-9]*$/, message: that.$t("lang.必须为整数") },
        ],
        pwd: [
          {
            validator({ itemValue }) {
              if (itemValue) {
                if (itemValue.length !== 4) {
                  return new Error(that.$t("lang.密码为四位数字"));
                } else if (itemValue.length == 4 && itemValue !== "8672") {
                  return new Error(that.$t("lang.密码错误"));
                }
              }
            },
          },
        ],
      },
    };
  },
  methods: {
    close() {
      let that = this;
      that.$parent.$parent.setDialogVisible();
    },
    updateConfig() {
      let that = this;
      //获取选择的接口公司名称
      that.loading = true;
      that.axios
        .post("WXCW/UpdateConfig", that.formData)
        .then(function (response) {
          if (response.data.code == 101) {
            that.loading = false;
            that.$vxe.modal.message({
              content: that.$t("lang.保存成功"),
              status: "success",
            });
            that.$parent.$parent.setDialogVisible();
          }
        })
        .catch(function () {
          that.loading = false;
          that.$vxe.modal.message({
            content: that.$t("lang.保存失败"),
            status: "error",
          });
        });
    },
    checkPassword() {
      let that = this;
      if (that.formData.pwd == "8672") {
        that.isSwitchDisabled = false;
      } else {
        that.isSwitchDisabled = true;
      }
    },
    getInformation() {
      let that = this;
      that.axios
        .post("WXCW/GetConfig1", { serialNumber: that.serialNumber })
        .then(function (response) {
          that.formData = response.data.data;
        });
    },
  },
  mounted() {
    let that = this;
    that.getInformation();
    that.axios
      .post("Base_SystemBaseData/GetListByDataType", {
        dataType: "接口公司名称",
        pageSize: that.pageSize,
        currentPage: that.currentPage,
        total: 0,
      })
      .then(function (response) {
        that.optionsDataType = response.data.data;
      });
  },
};
</script>

<style></style>
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentUser: {},
    //出货单相关
    selectProducts: [],
  },
  getters: {
    doneWeiXinUserInfo: (state) => {
      if (state.currentUser.weiXinUserInfo) {
        return {};
        // return JSON.parse(state.currentUser.weiXinUserInfo);
      } else {
        return {};
      }
    },
    getSelectProducts: (state) => {
      return state.selectProducts.filter((p) => {
        return p.selectCount > 0;
      });
    },
    getSelectProductsCount: (state) => {
      let result = 0;
      state.selectProducts.forEach((element) => {
        if (!isNaN(element.selectCount)) {
          result += element.selectCount;
        }
        // console.log(element.selectCount);
      });

      return result;
    },
  },
  mutations: {
    setCurrentUser(state, currentUser) {
      state.currentUser = currentUser;
    },
    setSelectProducts(state, obj) {
      let b = -1;
      state.selectProducts.forEach((element) => {
        if (element.id == obj.id) {
          element.selectCount = obj.selectCount;
          b = -2;
        }
      });
      if (b == -1) {
        state.selectProducts.push(obj);
      }
    },
  },
  actions: {
    getCurrentUser(context) {
      Vue.axios.get("Base/GetCurrentUser").then(function (response) {
        context.commit("setCurrentUser", response.data.data);
      });
    },
  },
  modules: {},
});

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home";
import Login from "../views/Login";
import StockManage from "../views/StockManage/StockManage";
import DeviceData from "../views/DeviceData/DeviceData";
import SystemSetting from "../views/SystemSetting/SystemSetting";
import SystemBaseData from "../views/SystemSetting/SystemBaseData/SystemBaseData";
import Menu from "../views/SystemSetting/Menu/Menu";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import GZHRecord from "../views/SystemSetting/GZHRecord/GZHRecord";
import HDWY01_Result from "../views/DeviceData/HDWY01/HDWY01_Result";
import HDWY01_DAT from "../views/DeviceData/HDWY01/HDWY01_DAT";
import HDWY01_Operate_Main from "../views/DeviceData/HDWY01/HDWY01_Operate_Main";
import DeviceData_Default from "../views/DeviceData/DeviceData_Default";
import SystemSetting_Default from "../views/SystemSetting/SystemSetting_Default";
import StockManage_Default from "../views/StockManage/StockManage_Default";
import HDWY01_Operate from "../views/DeviceData/HDWY01/HDWY01_Operate";
import i18n from "../i18n";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/Login",
  },
  {
    name: "S",
    path: "/S",
    component: () => import("../views/Scan/Scan"),
  },
  {
    name: "Test",
    path: "/Test",
    component: () => import("../views/Test"),
  },
  {
    path: "/AutoLogin",
    name: "AutoLogin",
    component: () => import("../views/Scan/AutoLogin"),
  },
  {
    path: "/Login",
    name: "Login",
    component: Login,
  },
  { path: "/BigScreen", name: "BigScreen", component: () => import("../views/BigScreen/BigScreen") },
  { path: "/BigScreenForWXCW", name: "BigScreenForWXCW", component: () => import("../views/BigScreenForWXCW/BigScreenForWXCW") },
  { path: "/BigScreenForWXCW1", name: "BigScreenForWXCW1", component: () => import("../views/BigScreenForWXCW/BigScreenForWXCW1") },
  { path: "/WXCW03ProjectExport/:projectId", name: "WXCW03ProjectExport", component: () => import("../views/DeviceData/WXCW03/WXCW03ProjectExport"), props: true },
  {
    path: "/WapHome",
    name: "WapHome",
    component: () => import("../views/WapHome"),
    children: [
      { name: "WapMyHome", path: "/WapMyHome", component: () => import("../views/WAP/WapMyHome") },
      { name: "WapMy", path: "/WapMy", component: () => import("../views/WAP/WapMy") },
      { name: "WapMes", path: "/WapMes", component: () => import("../views/WAP/WapMes") },
    ],
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    children: [
      {
        name: "StockManage",
        path: "/StockManage",
        component: StockManage,
        meta: ["仓库管理"],
        children: [
          { name: "StockManage_Default", path: "/StockManage/StockManage_Default", component: StockManage_Default, meta: ["仓库管理"] },
          { name: "ProductWarehouse", path: "/StockManage/ProductWarehouse", component: () => import("../views/StockManage/ProductWarehouse/Index"), meta: ["产品出入库查询"] },
        ],
      },
      {
        path: "/DeviceData",
        name: "DeviceData",
        component: DeviceData,
        meta: [i18n.t("lang.设备数据")],
        children: [
          { name: "DeviceData_Default", path: "/DeviceData/DeviceData_Default", component: DeviceData_Default, meta: [i18n.t("lang.设备数据")] },
          {
            name: "HDWY01_Operate_Main",
            path: "/DeviceData/HDWY01_Operate_Main",
            component: HDWY01_Operate_Main,
            meta: ["设备数据", "恒电位恒电流", "恒电位恒电流操作"],
            children: [{ name: "HDWY01_Operate", path: "/DeviceData/HDWY01_Operate_Main/HDWY01_Operate", component: HDWY01_Operate, meta: ["设备数据", "恒电位恒电流", "恒电位恒电流操作"] }],
          },
          { name: "HDWY01_Result", path: "/DeviceData/HDWY01_Result", component: HDWY01_Result, meta: ["设备数据", "恒电位恒电流", "恒电位恒电流查询"] },
          { name: "HDWY01_DAT", path: "/DeviceData/HDWY01_DAT", component: HDWY01_DAT, meta: ["设备数据", "恒电位恒电流", "恒电位恒电流DAT"] },
          { name: "PZD100Result", path: "/DeviceData/PZD100Result", component: () => import("../views/DeviceData/PZD100/PZD100Result"), meta: ["设备数据", "平整度", "8型平整度数据查询"] },
          { name: "PZD801Result", path: "/DeviceData/PZD801Result", component: () => import("../views/DeviceData/PZD801/PZD801Result"), meta: ["设备数据", "平整度", "7型平整度数据查询"] },
          { name: "PZD101Dat", path: "/DeviceData/PZD101Dat", component: () => import("../views/DeviceData/PZD101/PZD101Dat"), meta: ["设备数据", "平整度", "6型平整度DAT"] },
          { name: "JLCT0View", path: "/DeviceData/JLCT0View", component: () => import("../views/DeviceData/JLCT/JLCT0View"), meta: [i18n.t("lang.设备数据"), i18n.t("lang.静力触探"), i18n.t("lang.手动单通道")] },
          { name: "JLCT1View", path: "/DeviceData/JLCT1View", component: () => import("../views/DeviceData/JLCT/JLCT1View"), meta: [i18n.t("lang.设备数据"), i18n.t("lang.静力触探"), i18n.t("lang.自动单通道")] },
          { name: "JLCT2View", path: "/DeviceData/JLCT2View", component: () => import("../views/DeviceData/JLCT/JLCT2View"), meta: [i18n.t("lang.设备数据"), i18n.t("lang.静力触探"), i18n.t("lang.手动双通道")] },
          { name: "JLCT3View", path: "/DeviceData/JLCT3View", component: () => import("../views/DeviceData/JLCT/JLCT3View"), meta: [i18n.t("lang.设备数据"), i18n.t("lang.静力触探"), i18n.t("lang.自动双通道")] },
          { name: "JLCT6View", path: "/DeviceData/JLCT6View", component: () => import("../views/DeviceData/JLCT/JLCT6View"), meta: [i18n.t("lang.设备数据"), i18n.t("lang.静力触探"), i18n.t("lang.单通道带剪切力")] },
          { name: "WXCW01Query", path: "/DeviceData/WXCW01Query", component: () => import("../views/DeviceData/WXCW01/WXCW01Query"), meta: ["设备数据", "无线测温", "无线测温仪"] },
          { name: "WHMD08Result", path: "/DeviceData/WHMD08Result", component: () => import("../views/DeviceData/WHMD08/WHMD08Result"), meta: ["设备数据", "土壤无核", "土壤无核数据查询"] },
          { name: "WXCW03GCGL", path: "/DeviceData/WXCW03GCGL", component: () => import("../views/DeviceData/WXCW03/WXCW03GCGL"), meta: [i18n.t("lang.设备数据"), i18n.t("lang.无线测温仪"), i18n.t("lang.工程管理")] },
          { name: "WXCW03MyProject", path: "/DeviceData/WXCW03MyProject", component: () => import("../views/DeviceData/WXCW03/WXCW03MyProject"), meta: [i18n.t("lang.设备数据"), i18n.t("lang.无线测温仪"), i18n.t("lang.我的工程")] },
          { name: "WXCW03MyProject1", path: "/DeviceData/WXCW03MyProject/:projectId", component: () => import("../views/DeviceData/WXCW03/WXCW03MyProject"), meta: [i18n.t("lang.设备数据"), i18n.t("lang.无线测温仪"), i18n.t("lang.我的工程")], props: true },
          { name: "WXCW03MyProject2", path: "/DeviceData/WXCW03MyProject/:projectId/:cwId", component: () => import("../views/DeviceData/WXCW03/WXCW03MyProject"), meta: [i18n.t("lang.设备数据"), i18n.t("lang.无线测温仪"), i18n.t("lang.我的工程")], props: true },
          { name: "WXCW03BJGL", path: "/DeviceData/WXCW03BJGL", component: () => import("../views/DeviceData/WXCW03/WXCW03BJGL"), meta: [i18n.t("lang.设备数据"), i18n.t("lang.无线测温仪"), i18n.t("lang.报警管理")] },
          { name: "WXCW03MyDevice", path: "/DeviceData/WXCW03MyDevice", component: () => import("../views/DeviceData/WXCW03/WXCW03MyDevice"), meta: [i18n.t("lang.设备数据"), i18n.t("lang.无线测温仪"), i18n.t("lang.我的设备")] },
          { name: "WXCW03Avg", path: "/DeviceData/WXCW03Avg", component: () => import("../views/DeviceData/WXCW03/WXCW03Avg"), meta: [i18n.t("lang.设备数据"), i18n.t("lang.无线测温仪"), i18n.t("lang.平均温度查询")] },
          { name: "WXCW03BJRecord", path: "/DeviceData/WXCW03BJRecord", component: () => import("../views/DeviceData/WXCW03/WXCW03BJRecord"), meta: [i18n.t("lang.设备数据"), i18n.t("lang.无线测温仪"), i18n.t("lang.报警记录查询")] },
          { name: "CFY001Result", path: "/DeviceData/CFY001Result", component: () => import("../views/DeviceData/CFY001/CFY001Result"), meta: [i18n.t("lang.设备数据"), i18n.t("lang.测缝仪"), i18n.t("lang.测缝仪数据查询")] },
          { name: "CZY005_Result", path: "/DeviceData/CZY005_Result", component: () => import("../views/DeviceData/CZY005/CZY005_Result"), meta: ["设备数据", "车辙仪", "车辙仪数据查询"] },
          { name: "CZY006_Result", path: "/DeviceData/CZY006_Result", component: () => import("../views/DeviceData/CZY006/CZY006_Result"), meta: ["设备数据", "车辙仪", "车辙仪数据查询(006)"] },
          { name: "WHMD01_Result", path: "/DeviceData/WHMD01_Result", component: () => import("../views/DeviceData/WHMD01/WHMD01_Result"), meta: ["设备数据", "无核密度", "无核密度数据查询"] },
          { name: "LLZ_Calc_Per", path: "/DeviceData/LLZ_Calc_Per", component: () => import("../views/DeviceData/LLZ801/LLZ_Calc_Per"), meta: ["设备数据", "无核密度", "无核密度数据查询"] },
          //WXCW81相关路由
          { name: "WXCW81MyProject", path: "/DeviceData/WXCW81MyProject", component: () => import("../views/DeviceData/WXCW81/WXCW81MyProject"), meta: [i18n.t("lang.设备数据"), i18n.t("lang.无线测温仪"), i18n.t("lang.我的工程")] },
          { name: "WXCW81MySensor", path: "/DeviceData/WXCW81MySensor", component: () => import("../views/DeviceData/WXCW81/WXCW81MySensor"), meta: [i18n.t("lang.设备数据"), i18n.t("lang.无线测温仪"), i18n.t("lang.测点绑定")] },
          { name: "WXCW81WCBJ", path: "/DeviceData/WXCW81WCBJ", component: () => import("../views/DeviceData/WXCW81/WXCW81WCBJ"), meta: [i18n.t("lang.设备数据"), i18n.t("lang.无线测温仪"), i18n.t("lang.温差报警")] },
          { name: "WXCW81SXXBJ", path: "/DeviceData/WXCW81SXXBJ", component: () => import("../views/DeviceData/WXCW81/WXCW81SXXBJ"), meta: [i18n.t("lang.设备数据"), i18n.t("lang.无线测温仪"), i18n.t("lang.上下限报警")] },
          { name: "WXCW81JWSLBJ", path: "/DeviceData/WXCW81JWSLBJ", component: () => import("../views/DeviceData/WXCW81/WXCW81JWSLBJ"), meta: [i18n.t("lang.设备数据"), i18n.t("lang.无线测温仪"), i18n.t("lang.降温速率报警")] },
          { name: "WXCW81YSSJ", path: "/DeviceData/WXCW81YSSJ", component: () => import("../views/DeviceData/WXCW81/WXCW81YSSJ"), meta: [i18n.t("lang.设备数据"), i18n.t("lang.无线测温仪"), i18n.t("lang.原始数据")] },
          { name: "WXCW81ProjectView", path: "/DeviceData/WXCW81ProjectView/:gcId", component: () => import("../views/DeviceData/WXCW81/WXCW81ProjectView"), meta: [i18n.t("lang.设备数据"), i18n.t("lang.无线测温仪"), i18n.t("lang.测点绑定")], props: true },
        ],
      },
      {
        path: "/SystemSetting",
        name: "SystemSetting",
        component: SystemSetting,
        meta: ["系统设置"],
        children: [
          { name: "SystemSetting_Default", path: "/SystemSetting/SystemSetting_Default", component: SystemSetting_Default, meta: ["系统设置"] },
          { name: "UserInfo", path: "/SystemSetting/UserInfo", component: () => import("../views/SystemSetting/UserInfo/Index"), meta: ["系统设置", "用户管理"] },
          { name: "SystemBaseData", path: "/SystemSetting/SystemBaseData", component: SystemBaseData, meta: ["系统设置", "系统参数设置"] },
          { name: "GZHRecord", path: "/SystemSetting/GZHRecord", component: GZHRecord, meta: ["系统设置", "公众号日志"] },
          { name: "Menu", path: "/SystemSetting/Menu", component: Menu, meta: ["系统设置", "菜单管理"] },
          { name: "Group", path: "/SystemSetting/Group", component: () => import("../views/SystemSetting/Group/Group"), meta: ["系统设置", "组与用户"] },
          { name: "OnLineDevice", path: "/SystemSetting/OnLineDevice", component: () => import("../views/SystemSetting/Device/OnLineDevice"), meta: ["系统设置", "在线设备"] },
          { name: "OnLineDevice1", path: "/SystemSetting/OnLineDevice1", component: () => import("../views/SystemSetting/Device/OnLineDevice1"), meta: ["系统设置", "在线设备"] },
          { name: "WXCWSensor", path: "/SystemSetting/WXCWSensor", component: () => import("../views/SystemSetting/WXCWSensor/Index"), meta: ["系统设置", "无线测温仪传感器注册"] },
          { name: "Product", path: "/SystemSetting/Product", component: () => import("../views/SystemSetting/Product/Index"), meta: ["系统设置", "产品管理"] },
          { name: "Device", path: "/SystemSetting/Device", component: () => import("../views/SystemSetting/Device/Index"), meta: ["系统设置", "设备序列号管理"] },
          { name: "AccessToken", path: "/SystemSetting/AccessToken", component: () => import("../views/SystemSetting/GZHRecord/AccessToken"), meta: ["系统设置", "AccessToken"] },
          { name: "Agent", path: "/SystemSetting/Agent", component: () => import("../views/SystemSetting/Agent/Index"), meta: ["系统设置", "经销商管理"] },
          { name: "CustomerService", path: "/SystemSetting/CustomerService", component: () => import("../views/SystemSetting/CustomerService/Index"), meta: ["系统设置", "经销商管理"] },
          { name: "WXCW01RiZhi", path: "/SystemSetting/WXCW01RiZhi", component: () => import("../views/SystemSetting/RiZhi/WXCW01RiZhi"), meta: ["系统设置", "无线测温日志"] },
          { name: "RHD_ShiPinXinXi", path: "/SystemSetting/RHD_ShiPinXinXi", component: () => import("../views/SystemSetting/RiZhi/RHD_ShiPinXinXi"), meta: ["系统设置", "软化点视频"] },
          { name: "DeviceUpgradeFile", path: "/SystemSetting/DeviceUpgradeFile", component: () => import("../views/SystemSetting/Upgrade/DeviceUpgradeFile"), meta: ["系统设置", "升级程序切换"] },
          { name: "DeviceUpgradeFixedPath", path: "/SystemSetting/DeviceUpgradeFixedPath", component: () => import("../views/SystemSetting/Upgrade/DeviceUpgradeFixedPath"), meta: ["系统设置", "固定路劲升级"] },
          { name: "WXCW03_Result_Fill", path: "/SystemSetting/WXCW03_Result_Fill", component: () => import("../views/SystemSetting/WXCW03/WXCW03_Result_Fill"), meta: ["系统设置", "无线测温日志(Core)"] },
          { name: "PZD801_DeviceConfig", path: "/SystemSetting/PZD801_DeviceConfig", component: () => import("../views/SystemSetting/PZD801/PZD801_DeviceConfig"), meta: ["设备配置", "平整度801配置"] },
          { name: "YinErDa_Device_Online", path: "/SystemSetting/YinErDa_Device_Online", component: () => import("../views/SystemSetting/YinErDa/YinErDa_Device_Online"), meta: ["银尔达", "在线设备"] },
          { name: "SystemLog", path: "/SystemSetting/SystemLog", component: () => import("../views/SystemSetting/Log/SystemLog"), meta: ["系统设置", "系统日志"] },
        ],
      },
    ],
  },

  { name: "WapMyTestRecord", path: "/WapMyTestRecord", component: () => import("../views/DeviceData/MyTestRecord/WapMyTestRecord") },
  { name: "WapWXCWSensorSearch", path: "/WapWXCWSensorSearch", component: () => import("../views/SystemSetting/WXCWSensor/WapWXCWSensorSearch") },
  { name: "WapHDWY01Operate", path: "/WapHDWY01Operate", component: () => import("../views/DeviceData/HDWY01/WapHDWY01Operate") },
  { name: "WapWDD001Operate", path: "/WapWDD001Operate/:serialNumber", component: () => import("../views/DeviceData/WDD001/WapWDD001Operate"), props: true },
  { name: "WapRegisterDevice", path: "/WapRegisterDevice", component: () => import("../views/SystemSetting/Device/WapRegisterDevice") },
  { name: "WapRegisterDevice1", path: "/WapRegisterDevice1", component: () => import("../views/SystemSetting/Device/WapRegisterDevice1") },
  { name: "WapRegisterDevice2", path: "/WapRegisterDevice2", component: () => import("../views/SystemSetting/Device/WapRegisterDevice2") },
  { name: "D", path: "/D", component: () => import("../views/DownLoad/DownLoad") },
  { name: "WapWXCW01Operate", path: "/WapWXCW01Operate/:serialNumber", component: () => import("../views/DeviceData/WXCW01/WapWXCW01Operate"), props: true },
  { name: "WapWXCW01Setting", path: "/WapWXCW01Setting/:serialNumber", component: () => import("../views/DeviceData/WXCW01/WapWXCW01Setting"), props: true },
  { name: "WapWXCW01View", path: "/WapWXCW01View/:serialNumber", component: () => import("../views/DeviceData/WXCW01/WapWXCW01View"), props: true },
  { name: "WapWXCW03Operate", path: "/WapWXCW03Operate/:serialNumber", component: () => import("../views/DeviceData/WXCW03/WapWXCW03Operate"), props: true },
  { name: "WapWXCW03Setting", path: "/WapWXCW03Setting/:serialNumber", component: () => import("../views/DeviceData/WXCW03/WapWXCW03Setting"), props: true },
  { name: "WapWXCW03View", path: "/WapWXCW03View/:serialNumber", component: () => import("../views/DeviceData/WXCW03/WapWXCW03View"), props: true },
  { name: "WapMaterielIndex", path: "/WapMaterielIndex", component: () => import("../views/Materiel/WapMaterielIndex") },
  { name: "WapLYT001Operate", path: "/WapLYT001Operate/:materielId", component: () => import("../views/DeviceData/LYT001/WapLYT001Operate"), props: true },
  { name: "WapLYT001View", path: "/WapLYT001View/:serialNumber", component: () => import("../views/DeviceData/LYT001/WapLYT001View"), props: true },
  { name: "WapJSY001Operate", path: "/WapJSY001Operate/:serialNumber", component: () => import("../views/DeviceData/JSY001/WapJSY001Operate"), props: true },
  { name: "WapJSY001View", path: "/WapJSY001View/:serialNumber", component: () => import("../views/DeviceData/JSY001/WapJSY001View"), props: true },
  { name: "WapMyDevice", path: "/WapMyDevice", component: () => import("../views/WAP/WapMyDevice") },
  { name: "WapPRINT1Operate", path: "/WapPRINT1Operate/:serialNumber", component: () => import("../views/DeviceData/PRINT1/WapPRINT1Operate"), props: true },
  { name: "WapProductWarehouseIn", path: "/WapProductWarehouseIn", component: () => import("../views/StockManage/ProductWarehouse/WapProductWarehouseIn") },
  { name: "WapProductWarehouseOut", path: "/WapProductWarehouseOut", component: () => import("../views/StockManage/ProductWarehouse/WapProductWarehouseOut") },
  { name: "WapNearbyAgent", path: "/WapNearbyAgent", component: () => import("../views/SystemSetting/Agent/WapNearbyAgent") },
  { name: "WapAgentAdd", path: "/WapAgentAdd", component: () => import("../views/SystemSetting/Agent/WapAgentAdd") },
  { name: "WapMyAgent", path: "/WapMyAgent", component: () => import("../views/SystemSetting/Agent/WapMyAgent") },
  { name: "WapCustomerServiceAdd", path: "/WapCustomerServiceAdd", component: () => import("../views/SystemSetting/CustomerService/WapCustomerServiceAdd") },
  { name: "WapMyCustomerService", path: "/WapMyCustomerService", component: () => import("../views/SystemSetting/CustomerService/WapMyCustomerService") },
  { name: "Wap_ZHCS_Produce", path: "/Wap_ZHCS_Produce", component: () => import("../views/SystemSetting/ZHCS/Wap_ZHCS_Produce") },
  { name: "Wap_SZR7_Produce", path: "/Wap_SZR7_Produce", component: () => import("../views/SystemSetting/SZR7/Wap_SZR7_Produce") },
  { name: "Wap_WDD008_Produce", path: "/Wap_WDD008_Produce", component: () => import("../views/SystemSetting/WDD008/Wap_WDD008_Produce") },
  { name: "Wap_YSY001_Produce", path: "/Wap_YSY001_Produce", component: () => import("../views/SystemSetting/YSY001/Wap_YSY001_Produce") },
  { name: "Wap_YSY002_Produce", path: "/Wap_YSY002_Produce", component: () => import("../views/SystemSetting/YSY002/Wap_YSY002_Produce") },
  { name: "Wap_HWSY01_Produce", path: "/Wap_HWSY01_Produce", component: () => import("../views/SystemSetting/HWSY01/Wap_HWSY01_Produce") },
  { name: "Wap_ZRD001_Produce", path: "/Wap_ZRD001_Produce", component: () => import("../views/SystemSetting/ZRD001/Wap_ZRD001_Produce") },
  { name: "Wap_ZRD004_Produce", path: "/Wap_ZRD004_Produce", component: () => import("../views/SystemSetting/ZRD004/Wap_ZRD004_Produce") },
  { name: "Wap_LLZ001_Produce", path: "/Wap_LLZ001_Produce", component: () => import("../views/SystemSetting/LLZ001/Wap_LLZ001_Produce") },
  { name: "Wap_RHD001_Produce", path: "/Wap_RHD001_Produce", component: () => import("../views/SystemSetting/RHD001/Wap_RHD001_Produce") },
  { name: "Wap_RHD008_Produce", path: "/Wap_RHD008_Produce", component: () => import("../views/SystemSetting/RHD008/Wap_RHD008_Produce") },
  { name: "Wap_DHY001_Produce", path: "/Wap_DHY001_Produce", component: () => import("../views/SystemSetting/DHY001/Wap_DHY001_Produce") },
  { name: "Wap_DLND01_Produce", path: "/Wap_DLND01_Produce", component: () => import("../views/SystemSetting/DLND01/Wap_DLND01_Produce") },
  { name: "Wap_CZY005_Produce", path: "/Wap_CZY005_Produce", component: () => import("../views/SystemSetting/CZY005/Wap_CZY005_Produce") },
  { name: "Wap_LLMD01_Produce", path: "/Wap_LLMD01_Produce", component: () => import("../views/SystemSetting/LLMD01/Wap_LLMD01_Produce") },
  { name: "Wap_PZD001_Produce", path: "/Wap_PZD001_Produce", component: () => import("../views/SystemSetting/PZD001/Wap_PZD001_Produce") },
  { name: "Wap_JLCT82_Produce", path: "/Wap_JLCT82_Produce", component: () => import("../views/SystemSetting/JLCT82/Wap_JLCT82_Produce") },
  { name: "Wap_ProductQRCode", path: "/Wap_ProductQRCode", component: () => import("../views/SystemSetting/ProductQRCode/Wap_ProductQRCode") },
  { name: "WapJSY002Operate", path: "/WapJSY002Operate/:serialNumber", component: () => import("../views/DeviceData/JSY002/WapJSY002Operate"), props: true },
  { name: "WapJSY002Detail", path: "/WapJSY002Detail", component: () => import("../views/DeviceData/JSY002/WapJSY002Detail") },
  { name: "WapJSY002Query", path: "/WapJSY002Query", component: () => import("../views/DeviceData/JSY002/WapJSY002Query") },

  { name: "WapHWSY02Operate", path: "/WapHWSY02Operate/:serialNumber", component: () => import("../views/DeviceData/HWSY02/WapHWSY02Operate"), props: true },
  { name: "WapRHD008Operate", path: "/WapRHD008Operate/:serialNumber", component: () => import("../views/DeviceData/RHD008/WapRHD008Operate"), props: true },

  { name: "WapDeviceEdit", path: "/WapDeviceEdit", component: () => import("../views/SystemSetting/Device/WapDeviceEdit") },
  { name: "WapCZCX01Operate", path: "/WapCZCX01Operate/:serialNumber", component: () => import("../views/DeviceData/CZCX01/WapCZCX01Operate"), props: true },
  { name: "WapLLZ001Operate", path: "/WapLLZ001Operate/:serialNumber", component: () => import("../views/DeviceData/LLZ001/WapLLZ001Operate"), props: true },
  { name: "Wap_LLZ100_Operate", path: "/Wap_LLZ100_Operate/:serialNumber/:testType", component: () => import("../views/DeviceData/LLZ100/Wap_LLZ100_Operate"), props: true },
  { name: "WapCZCX01CustomerSignNumber", path: "/WapCZCX01CustomerSignNumber/:customerSignNumber", component: () => import("../views/DeviceData/CZCX01/WapCZCX01CustomerSignNumber"), props: true },
  { name: "WapWXCW03BJRecordWCBJ", path: "/WapWXCW03BJRecordWCBJ", component: () => import("../views/DeviceData/WXCW03/WapWXCW03BJRecordWCBJ") },
  { name: "WapWXCW03BJRecord4Hour", path: "/WapWXCW03BJRecord4Hour", component: () => import("../views/DeviceData/WXCW03/WapWXCW03BJRecord4Hour") },
  { name: "WapWXCW03BJRecord1Day", path: "/WapWXCW03BJRecord1Day", component: () => import("../views/DeviceData/WXCW03/WapWXCW03BJRecord1Day") },
  { name: "WapWXCW03BJRecordSXXBJ", path: "/WapWXCW03BJRecordSXXBJ", component: () => import("../views/DeviceData/WXCW03/WapWXCW03BJRecordSXXBJ") },
  //WXCW81手机端相关路由
  { name: "WapWXCW81BJRecord1Day", path: "/WapWXCW81BJRecord1Day", component: () => import("../views/DeviceData/WXCW81/WapWXCW81BJRecord1Day") },
  { name: "WapWXCW81BJRecord4Hour", path: "/WapWXCW81BJRecord4Hour", component: () => import("../views/DeviceData/WXCW81/WapWXCW81BJRecord4Hour") },
  { name: "Wap_WXCW81_OneSensor", path: "/Wap_WXCW81_OneSensor", component: () => import("../views/DeviceData/WXCW81/Wap_WXCW81_OneSensor") },
  { name: "Wap_WXCW81_Operate", path: "/Wap_WXCW81_Operate/:serialNumber", component: () => import("../views/DeviceData/WXCW81/Wap_WXCW81_Operate"), props: true },
  { name: "Wap_WXCW81_WCBJ_Record", path: "/Wap_WXCW81_WCBJ_Record/:id", component: () => import("../views/DeviceData/WXCW81/Wap_WXCW81_WCBJ_Record"), props: true },
  { name: "Wap_WXCW81_SXXBJ_Record", path: "/Wap_WXCW81_SXXBJ_Record/:id", component: () => import("../views/DeviceData/WXCW81/Wap_WXCW81_SXXBJ_Record"), props: true },

  //出货单先关路由
  { name: "WapProductSelect", path: "/WapProductSelect", component: () => import("../views/Shipments/WapProductSelect") },
  { name: "WapProductSelectSubmit", path: "/WapProductSelectSubmit", component: () => import("../views/Shipments/WapProductSelectSubmit") },
  { name: "WapMyShipment", path: "/WapMyShipment", component: () => import("../views/Shipments/WapMyShipment") },
  { name: "WapZRD001", path: "/WapZRD001", component: () => import("../views/DeviceData/ZRD001/WapZRD001") },
  { name: "WapZRD008Operate", path: "/WapZRD008Operate/:serialNumber", component: () => import("../views/DeviceData/ZRD008/WapZRD008Operate"), props: true },
  { name: "WapPZD100Operate", path: "/WapPZD100Operate/:serialNumber", component: () => import("../views/DeviceData/PZD100/WapPZD100Operate"), props: true },
  //仓库+司机
  { name: "Wap_CangKu_Operate", path: "/Wap_CangKu_Operate", component: () => import("../views/Shipments/Wap_CangKu_Operate") },
  { name: "Wap_SiJi_Operate", path: "/Wap_SiJi_Operate", component: () => import("../views/Shipments/Wap_SiJi_Operate") },

  //不需要验证的路由
  //   { name: "WapWXCWSensorQuery", path: "/WapWXCWSensorQuery", component: () => import("../views/DeviceData/WXCW03/WapWXCWSensorQuery") },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach(function (to, from, next) {
  document.title = "SUMJ";
  NProgress.start();
  let path = to.path.toUpperCase();
  if (path === "/LOGIN" || path === "/AUTOLOGIN" || path === "/BIGSCREEN" || path == "/WAPWXCWSENSORSEARCH" || path == "/TEST") {
    return next();
  } else if (path === "/D") {
    next();
  } else if (path === "/S") {
    return next();
  } else if (!window.localStorage.getItem("token")) {
    NProgress.done();
    return next("/login");
  }
  next();
});

router.afterEach(() => {
  NProgress.done();
});

export default router;

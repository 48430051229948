<template>
    <el-container>
        <el-aside>
            <SumjSide style="height: 100%" :navHeader="0"></SumjSide>
        </el-aside>
        <el-container>
            <el-main style="display: flex;flex-direction: column;">
                <breadcrumb></breadcrumb>
                <el-collapse-transition>
                    <router-view></router-view>
                </el-collapse-transition>
            </el-main>
            <el-footer class="footer">
                <el-link type="primary">{{ $t("lang.由斯姆捷科技有限公司提供技术支持") }}</el-link>
            </el-footer>
        </el-container>
    </el-container>
</template>
<script>
import SumjSide from "../../components/SumjSide";
import Breadcrumb from "../StockManage/Breadcrumb";
export default {
    components: { SumjSide, Breadcrumb },
    methods: {},
    mounted() { },
};
</script>
<style>
.footer {
    display: flex;
    justify-content: center;
}
</style>
import "babel-polyfill";
import Vue from "vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import axios from "axios";
import VueAxios from "vue-axios";
import VXETable from "vxe-table";
import i18n from "./i18n";

import "./assets/global.css";
import "vant/lib/index.css";
import "./assets/iconfont/iconfont.css";
import "vxe-table/lib/style.css";

Vue.use(ElementUI);

Vue.use(VueAxios, axios);
Vue.config.productionTip = false;

Vue.use(VXETable);

Vue.prototype.axios1 = axios.create({ baseURL: process.env.VUE_APP_BaseUrl1 });

Vue.prototype.axios2 = axios.create({ baseURL: process.env.VUE_APP_BaseUrl2 });

Vue.prototype.axios3 = axios.create({ baseURL: process.env.VUE_APP_BaseUrl3 });

Vue.prototype.axios4 = axios.create({ baseURL: process.env.VUE_APP_BaseUrl4 });

axios.defaults.baseURL = process.env.VUE_APP_BaseUrl;

axios.interceptors.request.use(function (config) {
  config.headers["token"] = window.localStorage.getItem("token");
  return config;
});
axios.interceptors.response.use(
  function (response) {
    if (response.data.code != 101) {
      if (response.data.code == 301) window.location.href = "/";
    }
    return response;
  },
  function () {}
);

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

<template>
    <div>
        <el-row :gutter="5">
            <el-col :span="12">
                <el-divider>{{ $t("lang.新增绑定设备") }}</el-divider>
                <el-form ref="elform" label-width="150px" :model="formModel" :rules="rules">
                    <el-form-item :label="$t('lang.设备序列号')" prop="serialNumber">
                        <el-input v-model="formModel.serialNumber"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('lang.经销商电话')" prop="agentPhone">
                        <el-input v-model="formModel.agentPhone"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('lang.备注')" prop="remark">
                        <el-input v-model="formModel.remark"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="handleClick">{{ $t("lang.绑定") }}</el-button>
                        <el-button @click="$refs.elform.resetFields()">{{ $t("lang.重置") }}</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-tag v-show="tagCode == 101" size="medium">{{ tagText }}</el-tag>
                        <el-tag v-show="tagCode == 201" type="danger" size="medium">{{ tagText }}</el-tag>
                    </el-form-item>
                </el-form>
            </el-col>
            <el-col :span="12">
                <el-divider>{{ $t("lang.已绑定设备") }}</el-divider>
                <vxe-table ref="table1" :data="tableData" border :row-config="rowConfig" :loading="loading" :max-height="maxHeight">
                    <vxe-column width="65" :title="$t('lang.二维码')" align="center">
                        <template #default="{ row }">
                            <el-popover placement="left-start">
                                <vue-qr :logoSrc="imageUrl" :text="text" :size="150"></vue-qr>
                                <el-button type="primary" slot="reference" size="mini" icon="el-icon-full-screen" circle @click="cellClickEvent(row)"></el-button>
                            </el-popover>
                        </template>
                    </vxe-column>
                    <vxe-column field="serialNumber" :title="$t('lang.序列号')"></vxe-column>
                    <vxe-column field="deviceType" :title="$t('lang.设备类型')"></vxe-column>
                    <vxe-column field="insertTime" :title="$t('lang.绑定时间')"></vxe-column>
                    <vxe-column width="120" :title="$t('lang.操作')" align="center">
                        <template #default="{ row }">
                            <el-button type="danger" size="mini" @click="unbindDevice(row.id)" icon="el-icon-delete" circle></el-button>
                            <el-button v-if="row.deviceType == 'WXCW81'" type="primary" size="mini" @click="settingDevice(row)" icon="el-icon-setting" circle></el-button>
                        </template>
                    </vxe-column>
                </vxe-table>
            </el-col></el-row>
        <vxe-modal v-model="dialogVisible" :position="{ top: 200 }" :title="settingRow.serialNumber" width="600">
            <Dialog_WXCW81_DeviceSetting :serialNumber="settingRow.serialNumber" v-if="dialogVisible"></Dialog_WXCW81_DeviceSetting>
        </vxe-modal>
    </div>
</template>

<script>
import vueQr from "vue-qr";
import Dialog_WXCW81_DeviceSetting from "./WXCW81/Dialog_WXCW81_DeviceSetting.vue";

export default {
    components: {
        vueQr,
        Dialog_WXCW81_DeviceSetting,
    },
    data() {
        let that = this;
        return {
            dialogVisible: false,
            settingRow: {},
            maxHeight: 200,
            loading: false,
            tableData: [],
            rowConfig: { isHover: true },
            formModel: {
                serialNumber: "",
                agentPhone: "",
                remark: "",
            },
            tagCode: "",
            tagText: "",
            rules: {
                serialNumber: [
                    { required: true, message: that.$t("lang.请输入序列号") },
                ],
                agentPhone: [
                    { required: true, message: that.$t("lang.请输入经销商电话") },
                ],
                remark: [{ required: true, message: that.$t("lang.请输入备注") }],
            },
            imageUrl: require("@/assets/sumj_logo.png"),
            text: "",
        };
    },

    methods: {
        setDialogVisible() {
            let that = this;
            that.dialogVisible = false;
        },
        cellClickEvent(row) {
            this.text =
                "http://bjlcyq.cn/s?s=" + row.serialNumber + "&n=" + row.deviceType;
        },
        handleClick() {
            let that = this;
            that.tagCode = "";
            this.$refs.elform.validate((valid) => {
                if (valid) {
                    that.axios
                        .post("Base_Device/BindDevice", that.formModel)
                        .then(function (response) {
                            that.tagCode = response.data.code;
                            that.tagText = response.data.msg;
                            that.getMyDeviceList();
                        });
                }
            });
        },
        getMyDeviceList() {
            let that = this;
            that.loading = true;
            that.axios.post("Base_Device/GetMyDevice", {}).then((response) => {
                that.loading = false;
                that.tableData = response.data.data;
            });
        },
        unbindDevice(id) {
            let that = this;
            that.$confirm(that.$t("lang.确定要解绑吗")).then(function () {
                that.axios
                    .post("Base_Device/UnBindDevice", {
                        onlyInt: id,
                    })
                    .then(function () {
                        that.getMyDeviceList();
                    });
            });
        },
        settingDevice(row) {
            let that = this;
            that.settingRow = row;
            that.dialogVisible = true;
        }
    },
    mounted() {
        let that = this;
        that.getMyDeviceList();

        this.maxHeight = this.$parent.$el.offsetHeight - 150;
    },
};
</script>
<style scoped></style>
<template>
    <el-dialog :visible.sync="visible" title="编辑菜单" :close-on-click-modal="false">
        <el-form :rules="rules" :model="form" ref="editForm" v-loading="loading" label-width="90px">
            <el-row>
                <el-col :span="8">
                    <el-form-item label="Pid" prop="pid">
                        <el-input v-model.number="form.pid"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="Name" prop="name">
                        <el-input v-model="form.name"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="Url" prop="url">
                        <el-input v-model="form.url"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="8">
                    <el-form-item label="Icon" prop="icon">
                        <el-input v-model="form.icon"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="HaveChild" prop="haveChild">
                        <el-switch v-model="form.haveChild"></el-switch>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="IsChecked" prop="isChecked">
                        <el-input v-model.number="form.isChecked"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="8">
                    <el-form-item label="VueUrl" prop="vueUrl">
                        <el-input v-model="form.vueUrl"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="VueName" prop="vueName">
                        <el-input v-model="form.vueName"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="DeviceType" prop="deviceType">
                        <el-input v-model="form.deviceType"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="8">
                    <el-form-item label="英文名">
                        <el-input v-model="form.englishName"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="繁体名">
                        <el-input v-model="form.hK_Name"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">

                </el-col>
            </el-row>

            <el-row type="flex" justify="end">
                <el-form-item>
                    <el-button type="primary" @click="updateModel">保存</el-button>
                    <el-button @click="cancel">取消</el-button>
                </el-form-item>
            </el-row>
        </el-form>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            form: {},
            rules: {
                pid: [{ required: true, type: "number", message: "请输入数字" }],
                name: [{ required: true, message: "必填" }],
                isChecked: [{ type: "number", message: "请输入数字" }],
            },
            loading: false,
        };
    },
    methods: {
        getModel(id) {
            let that = this;
            that.axios
                .post("Sys_Menu/GetModel", { id: id })
                .then(function (response) {
                    that.form = response.data.data;
                });
        },
        cancel() {
            let that = this;
            that.visible = false;
        },
        edit: function (id) {
            let that = this;
            that.getModel(id);
            that.visible = true;
        },
        updateModel() {
            let that = this;
            that.$refs.editForm.validate((valid) => {
                if (valid) {
                    that.loading = true;
                    that.axios
                        .post("Sys_Menu/UpdateModel", that.form)
                        .then(function (response) {
                            that.loading = false;
                            if (response.data.code == 101) {
                                that.visible = false;
                                that.$parent.getList();
                            }
                        });
                } else return false;
            });
        },
    },
};
</script>

<style></style>